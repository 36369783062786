import { memo } from "react";

function Circle({ circle, circleSize, onMouseDown }) {
  const xPos = circle.x - circleSize / 2;
  const yPos = circle.y - circleSize / 2;
  const circleClass =
    "absolute rounded-full bg-secondaryDark hover:bg-secondaryDark shadow-circle no-drag";
  const onPress = () => {
    onMouseDown();
  };
  return (
    <div>
      <div
        id="circle"
        className={circleClass}
        style={{
          left: xPos + "px",
          top: yPos + "px",
          width: circleSize + "px",
          height: circleSize + "px",
        }}
        onMouseDown={onPress}
      ></div>
    </div>
  );
}

export default memo(Circle);
