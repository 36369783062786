import {
  canvasHeight,
  canvasWidth,
  defaultRoundData,
  defaultRoundSettings,
  currentRoundTimeBeforeStart,
  currentRoundTimeOnStart,
  defaultRoundTime,
  defaultCircleSize,
  defaultConcurrentCircles,
} from "./constants";

function generateCircles(setRoundData, circleSize, n, setNewCircleId) {
  for (let i = 0; i < n; i++) {
    generateCircle(setRoundData, circleSize, setNewCircleId);
  }
}

function generateCircle(setRoundData, circleSize, setNewCircleId) {
  const newCircleId = Math.floor(Math.random() * 2 ** 32);
  setRoundData((prev) => {
    let circles = prev.circles;
    let x, y;
    let found = false;
    let tries = 0;
    let maxTries = 10;
    while (!found) {
      if (tries >= maxTries) {
        return;
      }
      x =
        Math.floor(Math.random() * (canvasWidth - circleSize * 2)) + circleSize;
      y =
        Math.floor(Math.random() * (canvasHeight - circleSize * 2)) +
        circleSize;
      found = true;
      circles.map((circle) => {
        const xDiff = Math.abs(circle.x - x);
        const yDiff = Math.abs(circle.y - y);
        if (Math.sqrt(xDiff * xDiff + yDiff * yDiff) < circleSize + 1) {
          found = false;
        }
        return circle;
      });
    }

    const newCircle = { id: newCircleId, x: x, y: y };

    if (tries < maxTries) {
      circles = [...circles, newCircle];
    }

    return { ...prev, circles: circles };
  });
  if (setNewCircleId) {
    setNewCircleId(newCircleId);
  }
}

function getCircleIdByCursorPosition(circles, cursorX, cursorY, circleSize) {
  let circleIdFound = false;
  let circleId = null;
  circles.map((circle) => {
    if (circleIdFound) {
      return null;
    } else {
      let xDiff = Math.abs(circle.x - cursorX);
      let yDiff = Math.abs(circle.y - cursorY);
      let distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
      if (distance <= circleSize / 2) {
        circleId = circle.id;
        circleIdFound = true;
        return circle.id;
      }
      return circle;
    }
  });
  return circleId;
}

function isCursorInBounds(cursorX, cursorY) {
  return (
    0 < cursorX &&
    cursorX < canvasWidth &&
    0 < cursorY &&
    cursorY < canvasHeight
  );
}

function removeCircle(setRoundData, id) {
  setRoundData((prev) => {
    let circles = [...prev.circles.filter((circle) => circle.id !== id)];
    return { ...prev, circles: circles };
  });
}

function updateCorrectClicks(setRoundData) {
  setRoundData((prev) => {
    let correctClicks = prev.correctClicks + 1;
    return { ...prev, correctClicks: correctClicks };
  });
}

function updateMissClicks(setRoundData) {
  setRoundData((prev) => {
    let missClicks = prev.missClicks + 1;
    return { ...prev, missClicks: missClicks };
  });
}

function addDeltaTimeAndUpdateLastClick(setRoundData) {
  setRoundData((prev) => {
    let now = new Date();
    let deltaTimeClicks;
    if (prev.lastClick) {
      deltaTimeClicks = [
        ...prev.deltaTimeClicks,
        now.getTime() - prev.lastClick.getTime(),
      ];
    } else {
      deltaTimeClicks = [...prev.deltaTimeClicks];
    }
    let lastClick = now;
    return { ...prev, deltaTimeClicks: deltaTimeClicks, lastClick: lastClick };
  });
}

function setCurrentRoundTimeToBeforeStart(setRoundData) {
  setRoundData((prev) => {
    let currentRoundTime = currentRoundTimeBeforeStart;
    return { ...prev, currentRoundTime: currentRoundTime };
  });
}

function updateCurrentRoundTime(setRoundData) {
  setRoundData((prev) => {
    let currentRoundTime = prev.currentRoundTime + 1;
    return { ...prev, currentRoundTime: currentRoundTime };
  });
}

function clearRoundSettings(setRoundSettings) {
  setRoundSettings(defaultRoundSettings);
}

function clearRoundData(setRoundData) {
  setRoundData(defaultRoundData);
}

function startRound(setRoundData, roundSettings, mode) {
  clearRoundData(setRoundData);
  setRoundData((prev) => {
    let currentRoundTime = currentRoundTimeOnStart;
    return { ...prev, mode: mode, currentRoundTime: currentRoundTime };
  });
  generateCircles(
    setRoundData,
    roundSettings.circleSize,
    roundSettings.concurrentCircles,
    null
  );
}

function stopRound(setRoundData) {
  setCurrentRoundTimeToBeforeStart(setRoundData);
  setRoundData((prev) => {
    return { ...prev, run: false };
  });
}

function setDefaultRoundTime(setRoundSettings) {
  setRoundSettings((prev) => {
    return {
      ...prev,
      roundTime: defaultRoundTime,
    };
  });
}

function setDefaultCircleSize(setRoundSettings) {
  setRoundSettings((prev) => {
    return {
      ...prev,
      circleSize: defaultCircleSize,
    };
  });
}

function setDefaultConcurrentCircles(setRoundSettings) {
  setRoundSettings((prev) => {
    return {
      ...prev,
      concurrentCircles: defaultConcurrentCircles,
    };
  });
}

function circleClickExecution(
  setRoundData,
  circleId,
  circleSize,
  setNewCircleId
) {
  removeCircle(setRoundData, circleId);
  updateCorrectClicks(setRoundData);
  addDeltaTimeAndUpdateLastClick(setRoundData);
  generateCircle(setRoundData, circleSize, setNewCircleId);
}

function randomPosition(setPos, canvasWidth, canvasHeight) {
  setPos((prev) => {
    return {
      x: Math.random() * canvasWidth,
      y: Math.random() * canvasHeight,
    };
  });
}

export {
  generateCircles,
  updateCorrectClicks,
  updateMissClicks,
  setCurrentRoundTimeToBeforeStart,
  addDeltaTimeAndUpdateLastClick,
  removeCircle,
  updateCurrentRoundTime,
  clearRoundSettings,
  clearRoundData,
  startRound,
  setDefaultRoundTime,
  setDefaultCircleSize,
  setDefaultConcurrentCircles,
  circleClickExecution,
  randomPosition,
  getCircleIdByCursorPosition,
  isCursorInBounds,
  stopRound,
};
