import { roundSettingsHeight } from "../logic/constants";

export default function InputField({
  iconSrc,
  iconOnClick,
  name,
  value,
  min,
  max,
  onChange,
  disabled,
}) {
  return (
    <div className="flex flex-row "
    style={{
      height: roundSettingsHeight + "px" ,
    }}
    >
      <div
        className={
          " rounded-l-lg bg-secondary aspect-1/1 p-2 shadow-textInputIcon  " +
          (!disabled
            ? " img-hover-zoom hover:bg-secondaryDark hover:shadow-textInputIconHover"
            : "")
        }
        onClick={() => iconOnClick()}
      >
        <img
          className="no-drag h-[100%] aspect-1/1"
          src={iconSrc}
          alt={name}
        ></img>
      </div>
      <input
        type="text"
        name={name}
        className=" rounded-r-lg text-textTertiary text-3xl py-1 px-2 shadow-textInput"
        style={{
          width: "100%",
          height: roundSettingsHeight + "px",
          pointerEvents: disabled ? "none" : "",
        }}
        value={value}
        min={min}
        max={max}
        onChange={(e) => onChange(e)}
        required
      />
    </div>
  );
}
