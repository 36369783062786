import { useContext } from "react";
import { RoundDataContext, RoundSettingsContext } from "../context/Context";
import { updateMissClicks, circleClickExecution } from "../logic/circleLogic";
import { canvasHeight, canvasWidth, modes } from "../logic/constants";
import Circle from "./Circle";
import Cursor from "./Cursor";

export default function Canvas() {
  const [roundData, setRoundData] = useContext(RoundDataContext);
  const [roundSettings, setRoundSettings] = useContext(RoundSettingsContext);

  return (
    <div
      className="bg-white m-auto shadow-lg rounded-lg shadow-canvas"
      style={{
        width: canvasWidth + "px",
        height: canvasHeight + "px",
        pointerEvents:
          roundData.currentRoundTime === null ||
          roundData.mode === modes.algorithm ||
          roundData.mode === modes.train ||
          roundData.mode === modes.ai
            ? "none"
            : "",
      }}
      onClick={() => {
        updateMissClicks(setRoundData);
      }}
    >
      <div
        className="relative"
        style={{
          width: canvasWidth + "px",
          height: canvasHeight + "px",
        }}
      >
        {roundData.circles.map((circle) => {
          return (
            <Circle
              key={circle.id}
              circle={circle}
              circleSize={roundSettings.circleSize}
              onMouseDown={() => {
                circleClickExecution(
                  setRoundData,
                  circle.id,
                  roundSettings.circleSize
                );
              }}
            />
          );
        })}
        <div
          className="z-10 absolute text-3xl text-textTertiary"
          style={{
            right: 10,
            bottom: 5,
          }}
        >
          {roundData.currentRoundTime === null ||
          roundData.currentRoundTime === undefined
            ? ""
            : roundData.currentRoundTime + 1}
        </div>
        {( (roundData.mode === modes.algorithm || roundData.mode === modes.train || roundData.mode === modes.ai) && <Cursor />)}
      </div>
    </div>
  );
}
