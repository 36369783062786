import { useContext } from "react";
import {
  buttonHolderHeight,
  buttonHolderWidth,
  modes,
} from "../logic/constants";
import { RoundDataContext, RoundSettingsContext } from "../context/Context";
import Button from "./Button";
import { clearRoundData, startRound } from "../logic/circleLogic";

export default function ButtonHolder() {
  const [roundData, setRoundData] = useContext(RoundDataContext);
  const [roundSettings, setRoundSettings] = useContext(RoundSettingsContext);

  return (
    <div
      id="buttons"
      className="no-select bg-primary m-auto flex space-x-4 justify-center"
      style={{
        height: buttonHolderHeight + "px",
        width: buttonHolderWidth + "px",
      }}
    >
      <Button
        text={"Play"}
        onClick={() => {
          startRound(setRoundData, roundSettings, modes.human);
        }}
      />
      <Button
        text={"Algorithm"}
        onClick={() => {
          startRound(setRoundData, roundSettings, modes.algorithm);
        }}
      />
      <Button
        text={"Train"}
        onClick={() => {
          startRound(setRoundData, roundSettings, modes.train);
        }}
      />
      <Button
        text={"AimAI"}
        onClick={() => {
          startRound(setRoundData, roundSettings, modes.ai);
        }}
      />
      <Button text={"Clear"} onClick={() => clearRoundData(setRoundData)} />
    </div>
  );
}
