const pageWidth = 1200; //pixel
const canvasWidth = 800; //pixel
const canvasHeight = 500; //pixel
const statisticsWidth = pageWidth - canvasWidth; //pixel
const statisticsHeight = canvasHeight; //pixel
const roundSettingsHeight = 50; //pixel
const buttonHolderHeight = roundSettingsHeight; //pixel
const buttonHolderWidth = canvasWidth; //pixel

const maxSpeedPerInterval = 7;
const pointerSize = 22; //pixel

//round time in seconds
const defaultRoundTime = 30;
const defaultConcurrentCircles = 5;
const maxConcurrentCirclesForAI = 5;
//circle size in pixel
const defaultCircleSize = 80;

const defaultRoundSettings = {
  circleSize: defaultCircleSize,
  concurrentCircles: defaultConcurrentCircles,
  roundTime: defaultRoundTime,
};

const circlesAtStart = [];
const defaultCorrectClicks = 0;
const defaultMissClicks = 0;
const defaultLastClick = null;
const defaultDeltaTimeClicks = [];
const currentRoundTimeBeforeStart = null;
const currentRoundTimeOnStart = 0;
const modes = {
  human: "human",
  algorithm: "algorithm",
  train: "train",
  ai: "ai",
  none: "none",
};
const defaultMode = modes.none;
const defaultRoundData = {
  circles: circlesAtStart,
  correctClicks: defaultCorrectClicks,
  missClicks: defaultMissClicks,
  lastClick: defaultLastClick,
  deltaTimeClicks: defaultDeltaTimeClicks,
  currentRoundTime: currentRoundTimeBeforeStart,
  mode: defaultMode,
};
const stopClickOperationAfterMilliseconds = 30;

const defaultNextMove = {
  direction: {
    distance: 0,
    angle: 0,
  },
  click: false,
};

const algorithmIntervallInMilliseconds = 10;
const aiIntervallInMilliseconds = 10;

const typeNextMove = "nextMove";
const typeGetNextMove = "getNextMove";
const typeNextTrainingMove = "nextTrainingMove";
const typeGetNextTrainingMove = "getNextTrainingMove";
const typeSetInitialState = "setInitialState";
const typeCircleAdded = "circleAdded";
const typeCorrectClick = "correctClick";
const typeMissClick = "missClick";
const typeTrainingStop = "trainingStop"

const backendEndpoint = "ws://localhost:5999";

export {
  pageWidth,
  canvasWidth,
  canvasHeight,
  statisticsWidth,
  statisticsHeight,
  defaultRoundTime,
  modes,
  circlesAtStart,
  currentRoundTimeBeforeStart,
  currentRoundTimeOnStart,
  defaultConcurrentCircles,
  defaultCircleSize,
  defaultRoundSettings,
  defaultMode,
  defaultRoundData,
  roundSettingsHeight,
  buttonHolderHeight,
  pointerSize,
  buttonHolderWidth,
  stopClickOperationAfterMilliseconds,
  maxSpeedPerInterval,
  defaultNextMove,
  algorithmIntervallInMilliseconds,
  aiIntervallInMilliseconds,
  typeNextMove,
  typeGetNextMove,
  typeNextTrainingMove,
  typeGetNextTrainingMove,
  typeSetInitialState,
  typeCircleAdded,
  backendEndpoint,
  typeCorrectClick,
  typeMissClick,
  maxConcurrentCirclesForAI,
  typeTrainingStop,
};
