function round(value, n) {
  return Math.floor(value * Math.pow(10, n)) / Math.pow(10, n);
}

function round2(value) {
  return round(value, 2);
}

function fix(value, n) {
  return value.toFixed(n);
}
function fix2(value) {
  return fix(value, 2);
}

function roundAndFix(value, n) {
  return fix(round(value, n), n);
}

function roundAndFix2(value) {
  return fix(round(value, 2), 2);
}

function toPercentage(value) {
  return value * 100;
}

function average(arr) {
  let sum = 0;
  arr.map((e) => (sum += e));
  return sum / arr.length;
}

export {
  round,
  round2,
  fix,
  fix2,
  roundAndFix,
  roundAndFix2,
  toPercentage,
  average,
};
