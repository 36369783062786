import { useContext, useEffect, useState } from "react";
import useWebSocket from "react-use-websocket";
import {
  canvasWidth,
  canvasHeight,
  modes,
  pointerSize,
  stopClickOperationAfterMilliseconds,
  maxSpeedPerInterval,
  defaultNextMove,
  aiIntervallInMilliseconds,
  algorithmIntervallInMilliseconds,
  typeNextMove,
  typeNextTrainingMove,
  typeSetInitialState,
  typeGetNextTrainingMove,
  backendEndpoint,
  typeCorrectClick,
  typeMissClick,
  typeCircleAdded,
  typeGetNextMove,
  typeTrainingStop,
} from "../logic/constants";
import {
  circleClickExecution,
  randomPosition,
  getCircleIdByCursorPosition,
  updateMissClicks,
  isCursorInBounds,
  stopRound,
} from "../logic/circleLogic";
import {
  Cursor as CursorIMG,
  ClickedCursor as ClickedCursorIMG,
} from "../logic/img";
import { RoundDataContext, RoundSettingsContext } from "../context/Context";

export default function Cursor() {
  const [roundData, setRoundData] = useContext(RoundDataContext);
  const [roundSettings, setRoundSettings] = useContext(RoundSettingsContext);
  const [click, setClick] = useState(false);
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const [nextMove, setNextMove] = useState(defaultNextMove);
  const [newCircleId, setNewCircleId] = useState(null);
  const [sendInitialState, setSendInitialState] = useState(false);
  const {
    sendJsonMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(backendEndpoint, {
    onOpen: () => {
      console.log("Connected to backend!");
    },
    onClose: () => {
      console.log("Disconnected!");
    },
  });

  useEffect(() => {
    if (
      !roundData.run &&
      roundData.currentRoundTime !== null &&
      (roundData.mode === modes.algorithm ||
        roundData.mode === modes.train ||
        roundData.mode === modes.ai)
    ) {
      setRoundData((prev) => {
        return { ...prev, run: true };
      });
      randomPosition(setPos, canvasWidth, canvasHeight);
      setSendInitialState(true);
    }
  }, [
    roundData.run,
    roundData.currentRoundTime,
    roundData.mode,
    setRoundData,
    sendJsonMessage,
    roundSettings,
    roundData.circles,
    pos,
  ]);

  useEffect(() => {
    if (sendInitialState) {
      sendJsonMessage({
        type: typeSetInitialState,
        data: {
          cursor: pos,
          circleSize: roundSettings.circleSize,
          circles: roundData.circles,
          mode: roundData.mode,
          concurrentCircles: roundSettings.concurrentCircles,
        },
      });
      setSendInitialState(false);
      if (roundData.mode === modes.train) {
        sendJsonMessage({
          type: typeGetNextTrainingMove,
          data: {},
        });
      }
      // else if (roundData.mode === modes.ai) {
      //   sendJsonMessage({
      //     type: typeGetNextMove,
      //     data: {},
      //   });
      // }
    }
  }, [
    sendJsonMessage,
    sendInitialState,
    pos,
    roundSettings,
    roundData.circles,
    roundData.mode,
  ]);

  useEffect(() => {
    var interval = null;
    if (roundData.run && roundData.mode === modes.algorithm) {
      interval = setInterval(() => {
        let nearestCircle;
        let nearestDistance;
        let needNearestCircle = roundData.circles.length;
        if (needNearestCircle) {
          roundData.circles.map((circle) => {
            let xDiff = Math.abs(circle.x - pos.x);
            let yDiff = Math.abs(circle.y - pos.y);
            let distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
            if (!nearestCircle || distance < nearestDistance) {
              nearestCircle = circle;
              nearestDistance = distance;
            }
            return circle;
          });
          if (nearestDistance <= roundSettings.circleSize / 2) {
            setClick(true);
            circleClickExecution(
              setRoundData,
              nearestCircle.id,
              roundSettings.circleSize
            );
            nearestDistance = undefined;
            setTimeout(
              () => setClick(false),
              stopClickOperationAfterMilliseconds
            );
            needNearestCircle = false;
          } else {
            needNearestCircle = true;
          }
        }

        if (nearestDistance) {
          let xDiff = Math.abs(nearestCircle.x - pos.x);
          let yDiff = Math.abs(nearestCircle.y - pos.y);
          let distance = Math.sqrt(xDiff * xDiff + yDiff * yDiff);
          let angle = Math.atan(-yDiff / xDiff);

          if (distance > maxSpeedPerInterval) {
            distance = maxSpeedPerInterval;
          }
          let newY = Math.sin(angle) * distance;
          let newX = Math.cos(angle) * distance;
          if (pos.x > nearestCircle.x) {
            newX *= -1;
          }
          if (pos.y < nearestCircle.y) {
            newY *= -1;
          }
          setPos((prev) => {
            return { x: prev.x + newX, y: prev.y + newY };
          });
        }
      }, algorithmIntervallInMilliseconds);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    roundData.run,
    roundData.mode,
    roundData.circles,
    roundSettings,
    pos,
    click,
    setRoundData,
  ]);

  useEffect(() => {
    var interval = null;
    if (roundData.run && roundData.mode === modes.ai) {
      interval = setInterval(() => {
        let move;
        if(nextMove){
          move = nextMove;
        }else{
          move = defaultNextMove
        }
        let changeX =
          Math.cos((move.direction.angle * 3.14) / 180.0) *
          move.direction.distance;
        let changeY =
          Math.sin((move.direction.angle * 3.14) / 180.0) *
          move.direction.distance;
        //   let changeX =
        //   Math.cos((nextMove.direction.angle * 3.14) / 180.0) *
        //   nextMove.direction.distance;
        // let changeY =
        //   Math.sin((nextMove.direction.angle * 3.14) / 180.0) *
        //   nextMove.direction.distance;
        changeY *= -1;
        setNextMove(defaultNextMove);
        sendJsonMessage({
          type: typeGetNextMove,
          data: {},
        });

        let newCursorX = pos.x + changeX;
        let newCursorY = pos.y + changeY;
        setPos((prev) => {
          return { x: newCursorX, y: newCursorY };
        });
        if (move.click) {
          setClick(true);
          let circleId = getCircleIdByCursorPosition(
            roundData.circles,
            newCursorX,
            newCursorY,
            roundSettings.circleSize
          );
          if (circleId) {
            circleClickExecution(
              setRoundData,
              circleId,
              roundSettings.circleSize,
              setNewCircleId
            );
            sendJsonMessage({
              type: typeCorrectClick,
              data: {
                idToRemove: circleId,
              },
            });
          } else {
            if (isCursorInBounds(newCursorX, newCursorY)) {
              updateMissClicks(setRoundData);
              sendJsonMessage({
                type: typeMissClick,
                data: {},
              });
            }
          }
          setTimeout(
            () => setClick(false),
            stopClickOperationAfterMilliseconds
          );
        }
        
      }, aiIntervallInMilliseconds);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    roundData.run,
    roundData.mode,
    roundData.circles,
    roundSettings,
    pos,
    click,
    nextMove,
    setRoundData,
    sendJsonMessage,
  ]);

  useEffect(() => {
    if (roundData.run && roundData.mode === modes.train && nextMove) {
      setNextMove(null);
      let changeX =
        Math.cos((nextMove.direction.angle * 3.14) / 180.0) *
        nextMove.direction.distance;
      let changeY =
        Math.sin((nextMove.direction.angle * 3.14) / 180.0) *
        nextMove.direction.distance;
      changeY *= -1
      let newCursorX = pos.x + changeX;
      let newCursorY = pos.y + changeY;
      setPos((prev) => {
        return { x: newCursorX, y: newCursorY };
      });
      if (nextMove.click) {
        setClick(true);
        let circleId = getCircleIdByCursorPosition(
          roundData.circles,
          newCursorX,
          newCursorY,
          roundSettings.circleSize
        );
        if (circleId) {
          circleClickExecution(
            setRoundData,
            circleId,
            roundSettings.circleSize,
            setNewCircleId
          );
          sendJsonMessage({
            type: typeCorrectClick,
            data: {
              idToRemove: circleId,
            },
          });
        } else {
          if (isCursorInBounds(newCursorX, newCursorY)) {
            updateMissClicks(setRoundData);
            sendJsonMessage({
              type: typeMissClick,
              data: {},
            });
          }
          sendJsonMessage({
            type: typeGetNextTrainingMove,
            data: {},
          });
        }

        setTimeout(() => setClick(false), stopClickOperationAfterMilliseconds);
      } else {
        sendJsonMessage({
          type: typeGetNextTrainingMove,
          data: {},
        });
      }
    }
  }, [
    roundData.run,
    roundData.mode,
    roundData.circles,
    roundSettings,
    pos,
    click,
    nextMove,
    setRoundData,
    sendJsonMessage,
  ]);

  useEffect(() => {
    if (lastJsonMessage) {
      let messageType = lastJsonMessage.type;
      let messageData = lastJsonMessage.data;
      if (messageType === typeNextMove) {
        let nextMoveData = messageData;
        setNextMove(nextMoveData);
      } else if (messageType === typeNextTrainingMove) {
        let nextMoveData = messageData;
        setNextMove(nextMoveData);
      } else if (messageType === typeTrainingStop) {
        stopRound(setRoundData);
      } else {
        console.log("unknown message type!");
      }
    }
  }, [lastJsonMessage, setRoundData]);

  useEffect(() => {
    if (newCircleId) {
      const newCircle = roundData.circles.find((circle) => {
        return circle.id === newCircleId;
      });
      sendJsonMessage({
        type: typeCircleAdded,
        data: {
          newCircle: newCircle,
        },
      });
      setNewCircleId(null);
      sendJsonMessage({
        type: typeGetNextTrainingMove,
        data: {},
      });
    }
  }, [newCircleId, roundData.circles, sendJsonMessage]);

  return (
    <div
      className="absolute no-drag"
      style={{ left: pos.x + "px", top: pos.y + "px", zIndex: 999 }}
    >
      {click ? (
        <img
          className="aspect-1/1"
          src={ClickedCursorIMG}
          alt={"ClickedCursor"}
          style={{ width: pointerSize + "px", height: pointerSize + "px" }}
        ></img>
      ) : (
        <img
          className="aspect-1/1"
          src={CursorIMG}
          alt={"Cursor"}
          style={{ width: pointerSize + "px", height: pointerSize + "px" }}
        ></img>
      )}
    </div>
  );
}
