import { useContext } from "react";
import IconInputField from "./IconInputField";
import { RoundDataContext, RoundSettingsContext } from "../context/Context";
import { modes } from "../logic/constants";
import { Quantity, Size, Time } from "../logic/img";
import {
  setDefaultCircleSize,
  setDefaultConcurrentCircles,
  setDefaultRoundTime,
} from "../logic/circleLogic";

export default function RoundSettings() {
  const [roundData, setRoundData] = useContext(RoundDataContext);
  const [roundSettings, setRoundSettings] = useContext(RoundSettingsContext);

  function onChange(e) {
    setRoundSettings((prev) => {
      return { ...prev, [e.target.name]: Number(e.target.value) };
    });
  }
  return (
    <div className="flex flex-row gap-3 justify-center">
      <IconInputField
        iconSrc={Time}
        label="Round Time"
        name="roundTime"
        value={roundSettings.roundTime}
        onChange={(e) => onChange(e)}
        iconOnClick={() => {
          if (roundData.mode === modes.none) {
            setDefaultRoundTime(setRoundSettings);
          }
        }}
        disabled={roundData.mode !== modes.none}
      />
      <IconInputField
        iconSrc={Size}
        label="Circle Size"
        name="circleSize"
        value={roundSettings.circleSize}
        onChange={(e) => onChange(e)}
        iconOnClick={() => {
          if (roundData.mode === modes.none) {
            setDefaultCircleSize(setRoundSettings);
          }
        }}
        disabled={roundData.mode !== modes.none}
      />
      <IconInputField
        iconSrc={Quantity}
        label="Concurrent Circles"
        name="concurrentCircles"
        value={roundSettings.concurrentCircles}
        onChange={(e) => onChange(e)}
        iconOnClick={() => {
          if (roundData.mode === modes.none) {
            setDefaultConcurrentCircles(setRoundSettings);
          }
        }}
        disabled={roundData.mode !== modes.none}
      />
    </div>
  );
}
