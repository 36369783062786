import { useState, useEffect, useContext } from "react";
import { statisticsHeight, statisticsWidth } from "../logic/constants";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { RoundDataContext } from "../context/Context";
import { average, roundAndFix, toPercentage } from "../logic/util";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Statistics() {
  const [roundData, setRoundData] = useContext(RoundDataContext);

  const [timeChart, setTimeChart] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: "rgb(230, 230, 230, 1)",
        borderColor: "rgb(126, 126, 126, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [clickAccuracyChart, setClickAccuracyChart] = useState({
    labels: ["Missed", "Correct", "Total"],
    datasets: [
      {
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
        borderColor: [
          "rgba(255,99,132,1)",
          "rgba(75, 192, 192, 1)",
          "rgba(54, 162, 235, 1)",
        ],
        borderWidth: 1,
        data: [0, 0, 0],
      },
    ],
  });

  const [timeChartOptions, setTimeChartOptions] = useState({
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        color: "#8b93a2",
        align: "center",
        position: "top",
        text: "Average Delta Time - ∞ ms",
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 1000,
      },
    },
  });
  const [clickAccuracyChartOptions, setClickAccuracyChartOptions] = useState({
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      title: {
        display: true,
        color: "#8b93a2",
        align: "center",
        position: "top",
        text: "Click Accuracy - 100%",
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
  });

  function updateClickAccuracyChart(missClicks, correctClicks) {
    setClickAccuracyChart((prev) => {
      return {
        ...prev,
        datasets: [
          {
            data: [missClicks, correctClicks, correctClicks + missClicks],
          },
        ],
      };
    });
    let accuracy = roundAndFix(
      toPercentage(correctClicks / (missClicks + correctClicks)),
      2
    );
    setClickAccuracyChartOptions((prev) => {
      return {
        ...prev,
        plugins: {
          ...prev.plugins,
          title: {
            ...prev.plugins.title,
            text:
              "Click Accuracy - " + (isNaN(accuracy) ? 100 : accuracy) + "%",
          },
        },
      };
    });
  }
  function updateTimeChart(deltaTimeClicks) {
    setTimeChart((prev) => {
      return {
        ...prev,
        labels: [
          ...deltaTimeClicks.map((d, index) => {
            return index + 1;
          }),
        ],
        datasets: [
          {
            data: [...deltaTimeClicks],
          },
        ],
      };
    });
    let averageDeltaTime = roundAndFix(average(deltaTimeClicks), 2);
    setTimeChartOptions((prev) => {
      return {
        ...prev,
        plugins: {
          ...prev.plugins,
          title: {
            ...prev.plugins.title,
            text:
              "Average Delta Time - " +
              (isNaN(averageDeltaTime) ? "ထ" : averageDeltaTime) +
              " ms",
          },
        },
      };
    });
  }
  useEffect(() => {
    updateClickAccuracyChart(roundData.missClicks, roundData.correctClicks);
  }, [roundData.missClicks, roundData.correctClicks]);

  useEffect(() => {
    updateTimeChart(roundData.deltaTimeClicks);
  }, [roundData.deltaTimeClicks]);

  const chartClassName = "pr-2 rounded border border";
  return (
    <div
      className="w-[100%] h-[100%] bg-secondaryDark shadow-lg rounded-lg shadow-statistics flex justify-evenly flex-col"
      style={{
        width: statisticsWidth + "px",
        height: statisticsHeight + "px",
      }}
    >
      <div className="p-5 h-[100%] flex flex-col gap-5 justify-evenly ">
        <div className="text-4xl w-[60%] mx-auto bg-secondaryDarkDark rounded rounded-lg shadow-statisticsTitle">
          <div>Statistics</div>
        </div>
        <div className="h-[50%] shadow-lg rounded-md shadow-canvas">
          <Bar
            className={chartClassName}
            style={{ backgroundColor: "white" }}
            data={timeChart}
            options={timeChartOptions}
          />
        </div>
        <div className="h-[50%] shadow-lg rounded-md shadow-canvas">
          <Bar
            className={chartClassName}
            style={{ backgroundColor: "white" }}
            data={clickAccuracyChart}
            options={clickAccuracyChartOptions}
          />
        </div>
      </div>
    </div>
  );
}
