import { useState, useEffect } from "react";
import "./css/App.css";
import Canvas from "./components/Canvas";
import ButtonHolder from "./components/ButtonHolder";
import Statistics from "./components/Statistics";
import {
  pageWidth,
  defaultRoundSettings,
  defaultRoundData,
  canvasWidth,
  statisticsWidth,
  modes,
} from "./logic/constants";
import Header from "./components/Header";
import { RoundDataContext, RoundSettingsContext } from "./context/Context";
import {
  stopRound,
  updateCurrentRoundTime,
} from "./logic/circleLogic";
import RoundSettings from "./components/RoundSettings";
//TODO: sound
//TODO: absolute charts on hover and bigger and backgrond darker
//TODO: use local storage for preferences
//TODO: color picker for circl
//TODO: optimal algorithm; and algoroithm with noise random numbers
//TODO: fix crash to spawn many big circles
//TODO: circle spwan animation
//TODO: limit tries to find a place for new circle to 30 or so
//TOOD: would makse sense anzeige of acual circles on bord becuase if in setting 100 and only space for 10 at start would already be good;
//TODO: change the shadows maybe, maybe some other colors some tints or compelty other colors, or let it be
function App() {
  const [roundData, setRoundData] = useState(defaultRoundData);
  const [roundSettings, setRoundSettings] = useState(defaultRoundSettings);

  useEffect(() => {
    var interval = null;
    if (roundData.currentRoundTime >= roundSettings.roundTime && !(roundData.mode===modes.train) ){
      stopRound(setRoundData)
    }
    if (roundData.currentRoundTime !== null) {
      interval = setInterval(() => {
        updateCurrentRoundTime(setRoundData);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [roundData.currentRoundTime, roundSettings.roundTime, roundData.mode]);

  return (
    <RoundDataContext.Provider value={[roundData, setRoundData]}>
      <RoundSettingsContext.Provider value={[roundSettings, setRoundSettings]}>
        <div className="page text-text bg-primary w-[100%] h-screen ">
          <Header />
          <div id="container" className="bg-primary w-[100%] h-[100%] no-drag">
            <div
              className="flex flex-row mx-auto mt-16 gap-5"
              style={{
                width: pageWidth,
              }}
            >
              <div
                className="flex flex-col gap-5"
                style={{
                  width: canvasWidth,
                }}
              >
              </div>
              <div
                className="flex flex-col gap-5"
                style={{
                  width: canvasWidth,
                }}
              >
                <Canvas />
                <ButtonHolder />
              </div>
              <div
                className="flex flex-col gap-5"
                style={{
                  width: statisticsWidth,
                }}
              >
                <Statistics />
                <RoundSettings />
              </div>
            </div>
          </div>

          <div className="h-64 bg-primary"></div>
          <div className="h-64 bg-primary"></div>
        </div>
      </RoundSettingsContext.Provider>
    </RoundDataContext.Provider>
  );
}

export default App;
